import React, { Component } from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { buildBlocks } from "../services/blockGenerator"
// eslint-disable-line
import { CoreBlockFragment } from "@/components/CoreBlock" // eslint-disable-line
import { ButtonLink } from "@/components/reusable/Button"

class Job extends Component {
  render() {
    const { title } = this.props.data.wpJob

    this.props.pageContext.pageTitle = title
    this.props.pageContext.job = this.props.data.wpJob

    const generatedBlocks = buildBlocks(
      this.props.data.wpPage.blocks,
      {},
      { ...this.props.pageContext }
    )

    return (
      <Layout
        meta={this.props.data.wpJob}
        path={this.props.pageContext.pagePath}
        title={title}
        themeOptions={this.props.data.wp.themeOptions}
        displayMenu={this.props.data.wpPage.displayMenu}
      >
        {generatedBlocks}
        {this.props.data.wpPage.floatingButton.showButton && (
          <div
            className={`floating-button fixed bottom-4 right-6 z-10 [&_.brew-button]:m-0`}
          >
            <ButtonLink
              className={``}
              link={this.props.data.wpPage.floatingButton?.buttonLink?.url}
              variant={`primary`}
            >
              {this.props.data.wpPage.floatingButton?.buttonLink?.title}
            </ButtonLink>
          </div>
        )}
      </Layout>
    )
  }
}

export const query = graphql`
  query ($id: String, $jobPage: String) {
    wpPage(slug: { eq: $jobPage }) {
      id
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
      floatingButton {
        showButton
        buttonLink {
          title
          url
        }
      }
      displayMenu {
        utilityNav
        mainMenu
      }
    }
    wpJob(id: { eq: $id }) {
      id
      title
      Jobs {
        jobDetails
        introductionSnippet
        applicationEmail
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`

export default Job
